<template>
  <div class="dashboard-page">
    <nav
      class="breadcrumb is-centered is-hidden-tablet"
      aria-label="breadcrumbs"
      :style="{
        'background-color': custoLook.bckColor
      }"
    >
      <ul>
        <li class="is-active">
          <a class="has-text-black">Dashboard</a>
        </li>
        <li @click="toggleMenu()">
          <a class="pr-0" v-if="currentTab">{{ currentTab.title }}</a
          ><b-icon
            class="has-text-success"
            :icon="openMenu ? 'chevron-up' : 'chevron-down'"
          ></b-icon>
        </li>
      </ul>
    </nav>
    <div class="columns is-marginless">
      <div class="column is-narrow is-paddingless is-full-mobile">
        <aside class="menu" :class="{ 'is-hidden-mobile': !openMenu }">
          <div v-for="group in menuGroups" v-bind:key="group">
            <p v-if="group !== 'top'" class="menu-label">{{ group }}</p>
            <ul class="menu-list">
              <li v-for="tab in filterTabs(group)" v-bind:key="tab.title">
                <a
                  :id="'btn-dashboard-' + tab.hash"
                  :class="{
                    'is-active': tab.hash == currentHash
                  }"
                  @click="setHash(tab.hash)"
                  ><b-icon
                    v-if="tab.icon"
                    :icon="tab.icon"
                    size="is-small"
                    class="mr-1"
                  ></b-icon>
                  {{ tab.title }}
                </a>
              </li>
            </ul>
          </div>
          <div class="is-hidden-mobile" v-if="foundBeacon">
            <p class="menu-label">Help</p>
            <ul class="menu-list">
              <li>
                <a @click="toggleBeacon"
                  ><b-icon
                    icon="help-circle"
                    size="is-small"
                    class="mr-1"
                  ></b-icon>
                  Contact us</a
                >
              </li>
              <!-- <li>
                <a
                  ><b-icon
                    icon="book-open-variant"
                    size="is-small"
                    class="mr-1"
                  ></b-icon>
                  Help Center</a
                >
              </li> -->
            </ul>
          </div>
        </aside>
      </div>

      <main
        class="column is-full-mobile"
        :style="{
          'background-color': custoLook.bodyBckColor
        }"
      >
        <component
          v-if="currentTab"
          v-bind:is="currentTab.component"
        ></component>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  computed: {
    ...mapGetters([
      "currentUser",
      "teamAccount",
      "custoLook",
      "portalLook",
      "teamAccount"
    ]),
    foundBeacon() {
      return window.Beacon;
    },
    currentHash() {
      return this.$route.params.tab || "intro";
    },
    currentTab() {
      return this.tabs.find(tab => {
        return tab.hash == this.currentHash;
      });
    },
    menuGroups() {
      return this.tabs
        .map(tab => tab.group)
        .filter((item, pos, arr) => {
          return arr.indexOf(item) == pos;
        });
    },
    tabs() {
      let tab = [
        {
          title: "Get Started",
          icon: "playlist-check",
          hash: "intro",
          component: () =>
            import(/* webpackChunkName: "introTab" */ "./IntroTab.vue"),
          group: "top",
          hideportal: true
        },
        // {
        //   title: "👥Community",
        //   hash: "community",
        //   component: null,
        //   group: "top"
        // },
        {
          title: "New Game",
          icon: "plus-circle",
          hash: "create",
          component: () =>
            import(/* webpackChunkName: "createGTab" */ "./CreateGameTab.vue"),
          group: "simulation"
        },
        {
          title: "Games",
          icon: "gamepad-square",
          hash: "games",
          component: () =>
            import(/* webpackChunkName: "myGamesTab" */ "./GamesTab.vue"),
          group: "simulation"
        },
        {
          title: "Analysis",
          icon: "poll",
          hash: "analysis",
          component: () =>
            import(/* webpackChunkName: "analysisTab" */ "./AnalysisTab.vue"),
          group: "simulation"
        },
        {
          title: "Templates",
          icon: "content-copy",
          hash: "templates",
          component: () =>
            import(/* webpackChunkName: "analysisTab" */ "./TemplatesTab.vue"),
          group: "simulation"
        },
        {
          title: "Brand & Colors",
          icon: "palette",
          hash: "customize",
          component: () =>
            import(/* webpackChunkName: "brandingTab" */ "./CustomizeTab.vue"),
          group: "settings",
          hideportal: true
        },
        {
          title: "Profile",
          icon: "account",
          hash: "profile",
          component: () =>
            import(/* webpackChunkName: "profileTab" */ "./ProfileTab.vue"),
          group: "settings"
        },
        {
          title: "Team",
          icon: "account-multiple",
          hash: "team",
          component: () =>
            import(/* webpackChunkName: "teamsTab" */ "./TeamsTab.vue"),
          group: "settings"
        },
        {
          title: "Subscription",
          icon: "star",
          hash: "subscription",
          component: () =>
            import(
              /* webpackChunkName: "SubscriptionTab" */ "./SubscriptionTab.vue"
            ),
          group: "settings",
          hideportal: true
        }
      ];
      return tab;
    }
  },
  methods: {
    toggleMenu() {
      this.openMenu = !this.openMenu;
    },
    toggleBeacon() {
      if (window.Beacon) {
        window.Beacon("toggle");
      }
    },
    setHash(newHash) {
      this.openMenu = false;
      if (newHash === "logout") {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/");
        });
      } else if (newHash !== this.$route.params.tab) {
        this.$router.push({ name: "dashboard", params: { tab: newHash } });
      }
    },
    filterTabs(group) {
      return this.tabs.filter(
        tab =>
          tab.group == group &&
          (!this.portalLook || (this.portalLook && !tab.hideportal))
      );
    },
    updateGamesList() {
      if (this.currentUser && this.currentUser.uid) {
        this.$store.dispatch("bindUserGamesList", this.currentUser);
        this.$store.dispatch("bindUserTemplatesList", this.currentUser);
        if (this.teamAccount && this.teamAccount.members) {
          let otherMembers = [];
          Object.keys(this.teamAccount.members).forEach(memb => {
            if (memb !== this.currentUser.id) {
              otherMembers.push(memb);
            }
          });
          if (otherMembers.length > 0) {
            if (otherMembers.length > 10) {
              let severalGroups = [];
              while (otherMembers.length > 0) {
                severalGroups.push(otherMembers.splice(0, 10));
                this.$store.dispatch(
                  "bindSubteamGamesList",
                  severalGroups[severalGroups.length - 1]
                );
                this.$store.dispatch(
                  "bindSubteamTeamplatesList",
                  severalGroups[severalGroups.length - 1]
                );
              }
            } else {
              this.$store.dispatch("bindTeamGamesList", otherMembers);
              this.$store.dispatch("bindTeamTemplatesList", otherMembers);
            }
          }
        }
      }
    }
  },
  created() {
    if (!this.$route.params.tab || !this.currentTab) {
      this.openMenu = false;
      if (this.portalLook) {
        this.$router.replace({ name: "dashboard", params: { tab: "games" } });
      } else {
        this.$router.replace({ name: "dashboard", params: { tab: "intro" } });
      }
    }
    this.updateGamesList();
  },
  watch: {
    currentUser() {
      this.updateGamesList();
    },
    teamAccount() {
      this.updateGamesList();
    },
    currentHash() {
      if (!this.$route.params.tab || !this.currentTab) {
        if (this.teamAccount || this.portalLook) {
          this.setHash("games");
        } else {
          this.setHash("intro");
        }
      }
    }
  },
  data() {
    return {
      openMenu: false
    };
  }
};
</script>
<style lang="scss">
.dashboard-page {
  .columns.wrap-db {
    padding: 0;
    margin: 0;
  }
  main.column {
    display: inline-block;
    position: relative;
    overflow-y: auto;
    padding: 0;
  }
  .is-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  .breadcrumb {
    padding: 15px 0;
    margin-bottom: 0;
    ul {
      padding: 0;
    }
  }
}
</style>
